@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Cy-Grotesk';
  src: url(CyGrotesk-KeyBold.otf);
}

@font-face {
  font-family: 'Cy-Grotesk-Normal';
  src: url(CyGrotesk-KeyRegular.otf);
}

body {
  background-color: #261539;
  overflow: auto;
  font-family: "Cy-Grotesk";
}

.normal-font{
  font-family: "Cy-Grotesk-Normal";
}

.ikona:hover * {
  color: #772f6f;
}

.a {
  animation: pulse 1s linear infinite;
}

.img-hor {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.span {
  color: #772f6f;
}

.roza {
  color: #db9bd5;
}

.sporka{
  color: #F0E2FF;
}

.slide-in{
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #772f6f;
  transform-origin: bottom;
}

.slide-out{
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #772f6f;
  transform-origin: top;
}
